$screen-sm-min: 576px;
$screen-md-min: 577px; // 768px original
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

@mixin sm {
  @media (max-width: #{$screen-sm-min}) {
      @content;
  }
}
@mixin md {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
      @content;
  }
}
@mixin rwd($screen) {
  @media (min-width: $screen+'px') {
      @content;
  }
}