@import '_breakpoints.scss';
@import '_header.scss';
@import 'https://fonts.googleapis.com/css?family=Heebo:400,700,900&display=swap';
// Glide Carousel js
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";
:root{
  --azul: #7AA1B5;
  --blanco: #FFFFFF;
  --gris: #F2F2F2;
  --negro:#222222;
  --naranja: #F7941D;
  --maxw:1230px;
}
html{
  scroll-behavior: smooth;
}
body{
  background:white;
  font-family: 'Heebo', sans-serif;
  padding:0; margin:0;
  
}

h2{
  padding:0; 
  margin:0;
}
section.section{
  padding:10vh 1rem;
}
.container{
  margin:auto;
  width:auto;
  align-items: center;
  @include lg{
    width:90%;
    max-width: var(--maxw);
  }
}
.columns{
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include sm{
    flex-direction: column;
  }
  @include md{
    flex-direction: row;
  }
  &.reverse{
    @include md{
      flex-direction: row-reverse;
    }
  }
  .column.half{
    width: 100%;
    
    @include md{
    width:45%;
    }
    
  }
  
}
.is-centered{
  text-align: center;
  margin:auto;
}
.is-relative{
  position: relative;
}
.is-flex{
  display: flex;
}
.flex-start{
  justify-content: flex-start;
}
.has-padding-xl{
  .container{
    @include xl{
      margin-top:2em;
    }
  }
}
.has-padding-md{
  .container{
    @include xl{
      margin-top:5em;
    }
  }
}
.top-padding{
  height: 10vh;
}
.hero{
  position: relative;
  padding-top:20vh;
  height:65vh;
  @include md{
    height: 50vh;
  }
  &__body{
    position:relative;
    max-width: 80%;
    font-size:.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20vh 1rem;
    h2{
      font-weight: normal;
    }

    @include md{
      max-width:50%;
      padding:15vh;
      font-size:1rem;
    }
    @include xl{
      max-width: 40%;
      
    }

  }
  &__background{
    position: absolute;
    margin:auto;
    top:0%; left:0%;
    width:100%;
    height:100%;
    z-index: -1;
    img{
      width:100%;
      height:100%;
      object-fit: cover;
    }
  }
}
// iconos del menu mobile
.buttons{
  .social-icon{
    min-width: 3rem;
    min-height: 3rem;

  } 
}
//iconos en el hero
.ico-social{
  margin:1rem 0;
  a{
    display: flex;
    align-items: center;
    
    color: var(--negro);
    font-size: 1rem;
    font-weight: bolder;
    text-decoration: none;
    
    width: 2.4rem;
    
    img{
      min-width: 2.4rem;
      min-height: 2.4rem;
      margin-right: 1rem;
      box-shadow: 0 3px 8px var(--negro);
      border-radius: 50%;
      z-index: 1;
    }
    span{
      position: relative;
      @include md{
        //display: none;
        opacity:0;
        left: -50px;
        transition: opacity .25s ease-in, left .28s ease-out;
      }
    }
  }
  a:hover{
    width: auto;
    transition: width 1s ease-in;
    span{
      //display: block;
      @include md{
        opacity:1;
        left:0;
        transition: opacity .25s ease-in, left .28s ease-out;
      }
    }
  }
}

//iconos en el footer
.footer .icons{
  display: flex;
  flex-direction: column;
  margin-top:10vh;
  @include md{
    flex-direction: row;
    margin-top:0;
  }
  a{
    flex-direction: column;
    min-width: 200px;
    img{
      @include sm{
        margin-bottom:2rem;
      }
    }
  }
}

.image-responsive{
  max-width: 95vw;
}

section.whatis{
  background: var(--negro);
  text-align:center;
  color:var(--blanco);
  
}
section.analisis{
  background: var(--azul)
    url(/assets/separator1.svg)
    no-repeat
    top center;
  background-size: contain;
}
section.after-analisis{
  background: var(--blanco)
  url(/assets/separator2.svg)
  no-repeat
  top center;
  background-size: contain;
}
section.prediccion{
  background: var(--gris)
  url(/assets/separator-3.svg)
  no-repeat
  top center;
  background-size: contain;
}
.after-prediction{
  background: var(--blanco)
  url(/assets/separator-4.svg)
  no-repeat
  top center;
  background-size: contain;
}
section.ocr{
  background: var(--naranja)
  url(/assets/separator-5.svg)
  no-repeat
  top center;
  background-size: contain;
}
.after-ocr{
  background: var(--blanco)
  url(/assets/separator-6.svg)
  no-repeat
  top center;
  background-size: contain;
}
section.after-lenguaje{
  background: var(--blanco)
  url(/assets/separator-8.svg)
  no-repeat
  top center;
  background-size: contain;
}
section.lenguaje{
  background: var(--negro)
  url(/assets/separator-7.svg)
  no-repeat
  top left;
  color:var(--blanco);
}

section.contacto{
  background: var(--gris);
  color: var(--white);
  label{
    width: 100%;
    display: block;
    padding: .5rem;
  }
  input[type=text]{
    width: 90%;
    padding: .45rem;
    border: 0;
    border-radius: .2rem;
  }
  .mensaje{
    height: 200px;
    display: block;
    border-radius: .2rem;
    width: 95%;
    @include md{
    width: 100%;
    }
  }
 
  .button{
    background-color: var(--azul);
    border: 0;
    color: rgba(var(--brand-primary), 1);
    padding: .5rem .85rem;
    font-size: 1.1rem;
    margin: 1rem .5rem;
    border-radius: .4rem;
  }
}
footer.footer{
  padding: 2rem;
  .container{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include md{
      flex-direction: row;

    }
  }
}
.glide__bullet{
  background: var(--negro) ;
  @include md{
    transform: scale(1.6);
    margin: 0 .5rem 1rem .5rem;
  }
  &--active{
    background: var(--naranja);
    @include md{
      transform: scale(2);
    }
  }
}
.has-carousel{

  max-width: 90vw;
  @include sm{
    .image{
      max-height: 30vh;
    }
  }
  
  @include md{
    max-width: 600px;
    margin: auto;
    .image{
      width: 90%;
      height: auto;
      box-shadow: 0 3px 5px #000;
      background: white;
      margin: 5px;
    }
  }
}

.link{
  color: var(--azul);
}