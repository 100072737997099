.header{
  position:fixed;
  width:100vw;
  z-index: 3;
  @include sm{
    background:var(--negro);
  }
  @include md{
    background:rgba(255,255,255,.95);
  }
  .container{
    display:flex;
    overflow: hidden;
    margin:auto;
    justify-content: space-between;
    @include sm{
      flex-direction: column;
    }
  }
  .brand{
    @include sm{
      padding: 10px;
    }
    @include md{
      margin:1rem;
    }

  }
  @include md{
    margin-bottom: 1rem;
  }
}
/* NAVIGATION */
.header{
  .hamburger{
    @include sm{
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: fixed;
      top:.5rem;
      right: .5rem;
      width: 2.5rem;
      height: 2.5rem;
      padding: .4rem;
      border-radius: .2rem;
    }
    @include md{
      display: none;
    }
    span{
      display: block;
      background-color:var(--azul);  
      height: 2px;
      border-radius: 1px;
      margin:4px auto;
      width: 60%;
      transition: transform .4s ease-in;
      transform-origin: center;
    }
    .ham{
      width: 90%;
      transition: opacity 1s ease-out, width .4s ease-in;
      transition-delay: .1s;
    }

  }
  .dropdown{
    @include sm{
      height: 1px;
      overflow: hidden;
      transition: height 1s;
    }
  }
  .main-menu{
    @include sm{
      height:65vh;
      width: 95vw;
      margin:auto;
      border-radius: .4rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      transition: 1s background ease-in;
    }
    @include md{
      
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
    }
  }
  .main-nav-item{
    @include sm{
      padding: 2rem 0;
      width: 100%;
      text-align:center;
      color: var(--blanco);
      font-size: 1rem;
      font-weight: bold;
      text-decoration: none;
      &:not(:last-child){
        border-bottom: 1px solid var(--blanco);
      }
    }
    @include md{
      color: var(--black);
      text-decoration: none;
      font-weight: bolder;
      font-size:1.2rem;
      max-width: 23%;
      text-align: center;
      display: inline-block;
    }
    // &.active{
    //   @include sm{
    //     letter-spacing: 4px;
    //     font-size: 1.6rem;
    //     font-weight: bolder;
        
    //   }
    //   @include md{
    //     font-weight: bolder;
    //     color: rgba(var(--brand-primary), 1);
    //   }
    // }
  }
  
}
.header.active{
  position: fixed;
  top:0;
  width: 100vw;
  .hamburger{
    span{
      position: relative;
    }
    span:first-child{
      transform: rotate(-45deg) translate(-7px,7px);
    }
    span.ham{
      opacity: 0;
      width:1%;
      transition-delay: .8s;
      transition: opacity .5s ease-in, width .4s ease-out;
    }
    span:last-child{
      transform: rotate(45deg) translate(-7px,-7px);
    }
  }
  .dropdown{
    @include sm{
      height: 100vh;
      transition: 1s height ease-in;
    }
  }
  .main-menu{
    @include sm{
      background: rgba(var(--brand-primary),1);
      transition: 1s background ease-in;
    }
  }
  
  
}
.header{
  .buttons{
    @include sm{
      display: flex;
      justify-content: space-around;
      margin: 2rem 0;
    }
    @include md{
      display: none;
      
    }
  }
}
.brand-medium{
  min-width: 8rem;
  @include xl{
    min-width: 13rem;
  }
}
